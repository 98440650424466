<template>
  <contactBoxes :contact_us="contact_us" :loader="loader" />
  <contactForm />
  <mapView />
</template>

<script>
import contactBoxes from "../components/contactBoxes.vue";
import contactForm from "../components/contactForm.vue";
import mapView from "../components/mapView.vue";
import axios from "axios";
export default {
  data() {
    return {
      contact_us: [],
      loader: true,
    };
  },
  components: {
    contactBoxes,
    contactForm,
    mapView,
  },

  methods: {
    async getLanding() {
      await axios.get("/home").then((response) => {
        this.contact_us = response.data.data.contact_us;
        this.loader = false;
      });
    },
  },

  mounted() {
    this.getLanding();
  },
};
</script>

<style></style>
