<template>
  <div class="booking section-pad">
    <div class="container">
      <div class="col-md-10 margin-auto">
        <div class="flex-group-me">
          <h4 class="centered-title font-bold relative-parent">
            {{ $t("common.booking") }}
          </h4>
        </div>
        <div class="flex-group-me mb-5">
          <img :src="imgHead" alt="" />
        </div>
        <div class="main-loader-main" v-if="booking.length < 1">
          <div class="spinner-square">
            <div class="square-1 square"></div>
            <div class="square-2 square"></div>
            <div class="square-3 square"></div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mb-3"
            v-for="book in booking"
            :key="book"
          >
            <div class="book-main">
              <div class="flex-group-me">
                <div class="dashed-border mb-3">
                  <div class="circle-div">
                    <img :src="book.image" alt="" />
                  </div>
                </div>
              </div>

              <h5 class="text-center mb-3 font-bold">{{ book.title }}</h5>
              <p class="land-p text-center">
                {{ book.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["booking"],
  data() {
    return {
      imgHead: require("../assets/imgs/title-head 1.png"),
      imgBooking: require("../assets/imgs/Group.png"),
      imgPaper: require("../assets/imgs/Vector.png"),
      imgHand: require("../assets/imgs/Vector (1).png"),
    };
  },
  components: {},
};
</script>

<style></style>
