<template>
  <div class="landing-page section-pad pad-bottom relative-parent">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-12 mb-3">
          <div class="top-land mb-4">
            <div class="icon-container">
              <font-awesome-icon :icon="['fas', 'thumbs-up']" />
            </div>
            <div class="land-text">
              {{ landings.title }}
            </div>
          </div>
          <h3 class="mb-4">
            <span class="color-main font-bold">{{ $t("nav.mainname") }}</span>
            {{ $t("nav.rental") }}
          </h3>
          <p class="land-p" v-html="landings.description"></p>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <div class="main-loader-main balck" v-if="loader">
            <div class="spinner-small"></div>
          </div>
          <div class="slider-mini">
            <swiper
              :pagination="pagination"
              :modules="modules"
              :navigation="false"
              class="mySwiper"
              :autoplay="{
                delay: 3500,
                disableOnInteraction: false,
              }"
              :loop="true"
            >
              <swiper-slide
                class="home-item"
                v-for="slider in landings.images"
                :key="slider"
              >
                <img class="home-img" :src="slider.url" alt="" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <div class="back-div"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper";
export default {
  props: ["landings" , "loader"],
  data() {
    return {
      title: "",
      desc: "",
      sliders: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
      },

      modules: [Pagination, Autoplay],
    };
  },
};
</script>

<style>
/* .slider-mini .swiper-slide {
  height: 350px;
} */
.slider-mini img {
  width: 100%;
  height: auto;
  max-height: 340px;
  border-radius: 10px;
}
.slider-mini .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 14px;
  height: 7px;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}
.slider-mini .swiper-pagination-bullet-active {
  background-color: #000;
  padding: 0px 15px;
}
.slider-mini .swiper {
  padding-bottom: 40px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px !important;
  color: #fff;
}
</style>
