<template>
  <div class="contact-boxes section-pad relative-parent">
    <div class="container">
      <div class="flex-group-me">
        <h4 class="centered-title font-bold relative-parent">
          {{ $t("nav.contact") }}
        </h4>
      </div>
      <div class="flex-group-me mb-5">
        <img :src="imgHead" alt="" />
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-12 mb-4">
          <div class="contact-box">
            <div class="flex-group-me mb-3">
              <div class="icon-rad">
                <font-awesome-icon :icon="['far', 'clock']" />
              </div>
            </div>

            <div class="mid-row mb-3 text-center">{{ $t("common.numHours") }}</div>
            <div class="con-deatails font14 gray-col text-center">
              {{ contact_us.beginning_of_working_hours }}
              <div class="flex-group-me" v-if="loader">
                <div class="spinner-small" ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12 mb-4">
          <div class="contact-box">
            <div class="flex-group-me mb-3">
              <div class="icon-rad">
                <font-awesome-icon :icon="['fas', 'location-dot']" />
              </div>
            </div>

            <div class="mid-row mb-3 text-center">{{ $t("common.location") }}</div>
            <div class="con-deatails font14 gray-col text-center">
              {{contact_us.address}}
              <div class="flex-group-me" v-if="loader">
                <div class="spinner-small" ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12 mb-4">
          <div class="contact-box">
            <div class="flex-group-me mb-3">
              <div class="icon-rad">
                <font-awesome-icon :icon="['far', 'envelope']" />
              </div>
            </div>

            <div class="mid-row mb-3 text-center">{{ $t("common.inputemail") }}</div>
            <div class="con-deatails font14 gray-col text-center">
              {{contact_us.email}}
              <div class="flex-group-me" v-if="loader">
                <div class="spinner-small" ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12 mb-4">
          <div class="contact-box">
            <div class="flex-group-me mb-3">
              <div class="icon-rad">
                <font-awesome-icon :icon="['fas', 'phone']" />
              </div>
            </div>

            <div class="mid-row mb-3 text-center">{{ $t("common.inputphone") }}</div>
            <div class="con-deatails font14 gray-col text-center">
              {{contact_us.phone}}
              <div class="flex-group-me" v-if="loader">
                <div class="spinner-small" ></div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['contact_us' , 'loader'],
  data() {
    return {
      imgHead: require("../assets/imgs/title-head 1.png"),
    };
  },
  components: {},


};
</script>

<style></style>
