<template>
  <div class="cars section-pad relative-parent">
    <div class="container">
      <div class="flex-group-me">
        <h4 class="centered-title font-bold relative-parent">
          {{ $t("nav.cars") }}
        </h4>
      </div>
      <div class="flex-group-me mb-5">
        <img :src="imgHead" alt="" />
      </div>
      <div class="cars-slider" v-if="fleet.length">
        <swiper
          :pagination="pagination"
          :modules="modules"
          :breakpoints="{
            '1000': {
              slidesPerView: 4,
              spaceBetween: 35,
            },
            '800': {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            '500': {
              slidesPerView: 2,
              spaceBetween: 35,
            },
          }"
          :slides-per-view="1"
          :space-between="10"
          :navigation="true"
          :freeMode="true"
          :parallax="true"
          class="mySwiper"
          :autoplay="{
            delay: 3500,
            disableOnInteraction: false,
          }"
          :loop="false"
        >
          <swiper-slide class="home-item" v-for="slider in fleet" :key="slider">
            <div class="car-card">
              <img class="home-img mb-3" :src="slider.image" alt="" />
              <div class="car-type mb-3">{{ slider.title }}</div>
              <a
                v-bind:href="'https://api.whatsapp.com/send?phone=' + phone"
                class="main-btn"
                >{{ $t("common.booknow") }}</a
              >
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-else>
        <div class="flex-group-me mb-4" v-if="loader">
          <div class="spinner-small"></div>
        </div>
        <div class="not-yet" v-if="!loader">
          <div class="col-md-5 col-12 margin-auto">
            <div class="text-no">{{ $t("common.nocars") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
export default {
  props: ["fleet", "loader"],
  data() {
    return {
      imgHead: require("../assets/imgs/title-head 1.png"),
      phone: "",
    };
  },
  methods: {
    async getLanding() {
      await axios.get("/home").then((response) => {
        this.phone = response.data.data.contact_us.phone;
        console.log(response.data.data.contact_us.phone);
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
      },

      modules: [Pagination, Autoplay, Navigation],
    };
  },
  mounted() {
    this.getLanding();
    console.log(this.phone);
  },
};
</script>

<style>
.cars-slider img {
  width: 100%;
  height: 160px;
  max-height: 340px;
  border-radius: 10px;
}
.cars-slider .swiper-pagination {
  display: none;
}
.cars-slider .swiper-button-prev {
  right: 45%;
  top: 94%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #201f1d;
}
.cars-slider .swiper-button-next {
  left: 45%;
  top: 94%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #201f1d;
}
.cars-slider .swiper-button-next:after,
.cars-slider .swiper-button-prev:after {
  font-size: 14px !important;
  color: #fff;
}
.cars-slider .swiper {
  padding-bottom: 80px;
}
.cars-slider .swiper-slide {
  transition: all 0.4s ease-in-out;
}
</style>
