<template>
  <div class="contact-form section-pad p-0 relative-parent">
    <div class="container">
      <div class="contact-form-box">
        <div class="row">
          <div class="col-lg-5 col-12">
            <div class="img-right">
              <img :src="imgright" alt="contact-img" />
            </div>
          </div>
          <div class="col-lg-7 col-12">
            <div class="form-main">
              <form action="" @submit.prevent="contactUs" ref="contactForm">
                <div class="grid-main mb-4">
                  <div class="main-input-cont">
                    <h6 class="mb-2 font15">{{ $t("common.inputname") }}</h6>
                    <input
                      type="text"
                      class="main-input"
                      :placeholder="$t('common.nameplace')"
                      name="name"
                      v-model="name"
                    />
                    <div class="main-float">
                      <font-awesome-icon :icon="['far', 'circle-user']" />
                    </div>
                  </div>
                  <div class="main-input-cont">
                    <h6 class="mb-2 font15">{{ $t("common.inputphone") }}</h6>
                    <input
                      type="number"
                      class="main-input"
                      :placeholder="$t('common.phoneplace')"
                      name="phone"
                      v-model="phone"
                    />
                    <div class="main-float">
                      <font-awesome-icon :icon="['fas', 'phone']" />
                    </div>
                  </div>
                </div>
                <div class="main-input-cont mb-4">
                  <h6 class="mb-2 font15">{{ $t("common.inputemail") }}</h6>
                  <input
                    type="email"
                    class="main-input"
                    :placeholder="$t('common.emailplace')"
                    name="email"
                    v-model="email"
                  />
                  <div class="main-float">
                    <font-awesome-icon :icon="['far', 'envelope']" />
                  </div>
                </div>
                <div class="main-input-cont mb-4">
                  <h6 class="mb-2 font15">{{ $t("common.MessageSubject") }}</h6>
                  <input
                    type="text"
                    class="main-input"
                    :placeholder="$t('common.Messageplace')"
                    name="title"
                    v-model="title"
                  />
                  <div class="main-float">
                    <font-awesome-icon :icon="['far', 'message']" />
                  </div>
                </div>
                <div class="main-input-cont mb-4">
                  <h6 class="mb-2 font15">{{ $t("common.Messagereal") }}</h6>
                  <textarea
                    class="main-textarea"
                    id=""
                    rows="7"
                    :placeholder="$t('common.Messageplacereal')"
                    name="message"
                    v-model="message"
                  ></textarea>
                  <div class="main-float">
                    <font-awesome-icon :icon="['far', 'message']" />
                  </div>
                </div>

                <div class="flex-group-me">
                  <button type="submit" class="main-btn lg">
                    <div v-if="!disabled">{{ $t("common.Send") }}</div>

                    <div class="loading" role="status" v-if="disabled">
                      {{ $t("common.Sendpending") }}

                      <div
                        class="spinner-small-white"
                        v-if="disabled"
                      ></div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imgright: require("../assets/imgs/0e7f6d486f 1.png"),
      done: require("../assets/imgs/Frame.png"),
      disabled: false,
      phone: "",
      email: "",
      name: "",
      title: "",
      message: "",
      showModalContact: false,
    };
  },
  components: {},

  methods: {
    // send contact us
    async contactUs() {
      // console.log(document.querySelector("#doneContact"));
      this.disabled = true;
      const fd = new FormData(this.$refs.contactForm);
      fd.append("name", this.name);
      fd.append("phone", this.phone);
      fd.append("email", this.email);
      fd.append("title", this.title);
      fd.append("message", this.message);

      await axios.post("/introMessage", fd).then((response) => {
        // console.log(response.data);
        if (response.data.key == "success") {
          // this.$swal("", `${response.data.msg}`, "success");
          // document.querySelector("#doneContact").classList.add("show");
          document.querySelector("#bla").click();

          this.name = "";
          this.email = "";
          this.phone = "";
          this.title = "";
          this.message = "";
        } else {
          this.$swal("", `${response.data.msg}`, "error");
          // document.querySelector("#doneContact").classList.add("show");
        }
        this.disabled = false;
      });
    },
  },
  mounted() {},
};
</script>

<style>
.swal2-actions {
  display: none !important;
}
</style>
