<template>
  <div class="map-main relative-parent">
    <div class="map-div">
      <GMapMap
        :center="center"
        :options="options"
        :zoom="8"
        map-type-id="terrain"
        style="width: 100vw; height: 420px"
      >
        <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          @click="center = m.position"
        />
      </GMapMap>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      center: { lat: 1, lng: 1 },
      markers: [
        {
          position: {
            lat: 1,
            lng: 1,
          },
        },
      ],
    };
  },
  components: {},

  methods: {
    async getPosition() {
      await axios.get("/home").then((response) => {
        this.markers[0].position.lat = Number(response.data.data.contact_us.lat) ;
        this.markers[0].position.lng = Number(response.data.data.contact_us.long);
        this.center.lat = Number(response.data.data.contact_us.lat);
        this.center.lng = Number(response.data.data.contact_us.long);
      });
    },
  },
  mounted() {
    this.getPosition();
  },
};
</script>

<style></style>
