<template>
  <div class="all-offers section-pad relative-parent">
    <div class="container">
      <div class="flex-group-me">
        <h4 class="centered-title font-bold relative-parent">{{ $t("nav.offers") }}</h4>
      </div>
      <div class="flex-group-me mb-5">
        <img :src="imgHead" alt="" />
      </div>
      <div class="row margin-defi" v-if="offers.length">
        <div
          class="col-lg-4 col-md-6 col-12 mb-4"
          v-for="offer in offers"
          :key="offer.id"
        >
          <div class="offer-box mb-2">
            <div class="flex-group-me">
              <div class="sale-main">
                <div class="sale-inside">
                  <div>خصم</div>
                  <div class="bolder-div"> % {{ offer.value }} </div>
                </div>
              </div>
            </div>
            <div class="car-offer-con py-1">
              <img :src="offer.image" class="car-offer" alt="" />
            </div>

            <div class="offer-text">{{ offer.title }}</div>
          </div>
          <p class="land-p text-center p-2">{{ offer.description }}</p>
        </div>
      </div>
      <div v-else>
        <div class="flex-group-me mb-4" v-if="loader">
          <div class="spinner-small"></div>
        </div>
        <div class="not-yet" v-if="!loader">
          <div class="col-md-5 col-12 margin-auto">
            <div class="text-no">{{ $t("common.nooffers") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imgHead: require("../assets/imgs/title-head 1.png"),
      car: require("../assets/imgs/car_5.png"),
      offers: [],
      loader: true,
    };
  },
  components: {},

  methods: {
    async getOffers() {
      await axios.get("/offers").then((response) => {
        this.offers = response.data.data;
        this.loader = false;
      });
    },
  },

  mounted() {
    this.getOffers();
  },
};
</script>

<style></style>
