<template>
  <div class="stats section-pad pad-stats relative-parent">
    <div class="container">
      <div class="col-md-10 margin-auto">
        <div class="flex-group-me">
          <h4 class="centered-title font-bold relative-parent white">
            {{ $t("common.Statistics") }}
          </h4>
        </div>
        <div class="flex-group-me mb-4">
          <img :src="imgHead" alt="" />
        </div>
        <div class="main-loader-main" v-if="statistics.length < 1">
          <div class="spinner-square">
            <div class="square-1 square"></div>
            <div class="square-2 square"></div>
            <div class="square-3 square"></div>
          </div>
        </div>
        <p class="land-p stats-p mb-4" v-html="statistics.description"></p>
        <div class="flex-group-me">
          <div class="card-stat">
            <div class="stat-cont">
              <font-awesome-icon :icon="['far', 'circle-user']" />
            </div>
            <div class="left-stat">
              <div class="font13 gray-col">{{ $t("common.clients") }}</div>
              <div class="stat-real-num">{{ statistics.clientsCount }}+</div>
            </div>
          </div>
          <div class="card-stat">
            <div class="stat-cont">
              <font-awesome-icon :icon="['far', 'thumbs-up']" />
            </div>
            <div class="left-stat">
              <div class="font13 gray-col">{{ $t("common.satisfaction") }}</div>
              <div class="stat-real-num">{{ statistics.satisfaction }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="first-img" :src="imgright" alt="" />
    <img class="second-img" :src="imgleft" alt="" />

    <img class="third-img" :src="imgtop" alt="" />
    <img class="third-img-flip" :src="imgtopFlip" alt="" />

  </div>
</template>

<script>
export default {
  props: ["statistics"],
  data() {
    return {
      imgHead: require("../assets/imgs/title-head 1.png"),
      imgright: require("../assets/imgs/facts-right 1.png"),
      imgleft: require("../assets/imgs/facts-right 2.png"),
      imgtop: require("../assets/imgs/facts-left 1.png"),
      imgtopFlip: require("../assets/imgs/facts-flip1.png"),
    };
  },
  components: {},
};
</script>

<style></style>
