export default {
  "nav": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسطولنا"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراء العملاء"])},
    "branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفروع"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوظيف"])},
    "who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتاجير السيارات"])},
    "mainname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباحوث"])}
  },
  "common": {
    "Special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقات العملاء المميزين"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية الحجز"])},
    "Statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات بالارقام"])},
    "satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة رضا العملاء"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد عملائنا"])},
    "booknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احجز الان"])},
    "numHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" موقعنا"])},
    "inputname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "nameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء كتابة الاسم هنا"])},
    "inputphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "phoneplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال رقم الجوال"])},
    "inputemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "emailplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء كتابة البريد الالكتروني هنا"])},
    "MessageSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضوع الرسالة"])},
    "Messageplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل موضوع الرسالة"])},
    "Messagereal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتك او استفسارك"])},
    "Messageplacereal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتك او استفسارك"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل لنا الان"])},
    "Sendmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "Sendpending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الارسال"])},
    "sendreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف تقييم"])},
    "Qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل العلمي"])},
    "yourCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الزاتية"])},
    "uploadyourCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ارفاق السيرة الزاتبة"])},
    "IDphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الهوية"])},
    "uploadyourIDphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ارفاق صورة الهوية"])},
    "Starrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم بالنجوم"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك في البريد"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط سريعة"])},
    "inputPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى كتابة البريد الالكتروني الخاص بك"])},
    "inputbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتراك"])},
    "inputbtnpend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الاشتراك"])},
    "rightFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنفيذ مؤسسة اوامر الشبكة"])},
    "leftFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة 2023/2024 "])},
    "nocars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر سيارات حالية"])},
    "nooffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر عروض حالية"])},
    "nobranshes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر فروع حالية"])},
    "nopackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر باقات حالية"])}
  }
}