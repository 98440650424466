<template>
  <v-app>
    <v-main>
      <pageHeader />
      <router-view />
      <pageFooter />
    </v-main>
  </v-app>
</template>

<script>
import "@/assets/css/style.css";
import "@/assets/css/style-ltr.scss";
import pageHeader from "@/components/pageHeader.vue";
import pageFooter from "@/components/pageFooter.vue";
export default {
  components: {
    pageHeader,
    pageFooter,
  },

  mounted() {
    if (localStorage.getItem("locale") == "en") {
      document.querySelector("body").classList.add("ltr");
    } else {
      document.querySelector("body").classList.remove("ltr");
    }
  },
};
</script>
<style>
#app {
  font-family: "myfont", sans-serif;
}
a {
  text-decoration: none !important;
}
ul {
  margin-bottom: 0 !important;
}
</style>
