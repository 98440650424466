<template>
  <div class="all-branshes section-pad relative-parent">
    <div class="container">
      <div class="flex-group-me">
        <h4 class="centered-title font-bold relative-parent">{{ $t("nav.branches") }}</h4>
      </div>
      <div class="flex-group-me mb-5">
        <img :src="imgHead" alt="" />
      </div>

      <div class="row" v-if="branshes.length">
        <div
          class="col-md-6 col-12 mb-4"
          v-for="branshe in branshes"
          :key="branshe.id"
        >
          <div class="branshe-box">
            <img class="branshe-img" :src="branshe.image" alt="branshe-img" />
            <div class="left-branshe">
              <h5 class="mb-5">{{ branshe.name }}</h5>
              <div class="flex-branshe mb-4">
                <font-awesome-icon
                  class="icon-br"
                  :icon="['fas', 'location-dot']"
                />
                <div class="branshe-loc">{{ branshe.address }}</div>
              </div>
              <div class="flex-branshe mb-4">
                <font-awesome-icon
                  class="icon-br"
                  :icon="['far', 'envelope']"
                />
                <div class="branshe-loc">{{ branshe.email }}</div>
              </div>
              <div class="flex-branshe mb-4">
                <font-awesome-icon class="icon-br" :icon="['fas', 'phone']" />
                <div class="branshe-loc">{{ branshe.phone }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex-group-me mb-4" v-if="loader">
          <div class="spinner-small"></div>
        </div>
        <div class="not-yet" v-if="!loader">
          <div class="col-md-5 col-12 margin-auto">
            <div class="text-no">{{ $t("common.nobranshes") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imgHead: require("../assets/imgs/title-head 1.png"),
      imgbranshe: require("../assets/imgs/Rectangle 20.png"),
      branshes: [],
      loader: true,
    };
  },
  components: {},

  methods: {
    async getBranshes() {
      await axios.get("/branches").then((response) => {

        this.branshes = response.data.data;
        this.loader = false;
      });
    },
  },

  mounted() {
    this.getBranshes();
  },
};
</script>

<style></style>
