export default {
  "nav": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Cars"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Reviews"])},
    "branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branches"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment"])},
    "who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who Us"])},
    "rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for renting cars"])},
    "mainname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Bahooth"])}
  },
  "common": {
    "Special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special customer packages"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to book"])},
    "Statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics in numbers"])},
    "satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction rate"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of our clients"])},
    "booknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Now"])},
    "numHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of hours"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
    "inputname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "nameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inter username"])},
    "inputphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "phoneplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inter phone number"])},
    "inputemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please inter your Email"])},
    "MessageSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Subject"])},
    "Messageplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter message subject"])},
    "Messagereal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your message or inquiry"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us now"])},
    "Sendmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "Sendpending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])},
    "sendreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a review"])},
    "Messageplacereal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your message or inquiry"])},
    "Qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifications"])},
    "yourCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cv"])},
    "uploadyourCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload your cv"])},
    "IDphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload your ID photo"])},
    "uploadyourIDphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload your ID photo"])},
    "Starrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star rating"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to mail"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick links"])},
    "inputPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write your email"])},
    "inputbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscription"])},
    "inputbtnpend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in progress"])},
    "rightFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation of Awamer El Shabaka"])},
    "leftFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved 2023/2024"])},
    "nocars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cars currently available"])},
    "nooffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No current offers available"])},
    "nobranshes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No current branches available"])},
    "nopackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No current packages available"])}
  }
}