<template>
  <!--start loader-->
  <!-- <div class="loader" v-if="loading">
    <div class="logo-co-p">
      <img :src="loader" alt="" />
    </div>
    <div id="progressbar"></div>
  </div> -->
  <div class="nav-layer" @click="toggleme"></div>
  <section class="header-container">
    <div class="container">
      <div class="header">
        <div class="logo-header">
          <img :src="logo" alt="logo" />
          <div class="absword">{{ $t("nav.rental") }}</div>
        </div>
        <ul class="links">
          <li>
            <router-link
              @click="removeLayerLink"
              to="/"
              class="main-link"
              :class="{ active: $route.path === '/' }"
              >{{ $t("nav.Home") }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/carsPage"
              :class="{ active: $route.path === '/carsPage' }"
              class="main-link"
              @click="removeLayerLink"
              >{{ $t("nav.cars") }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/opinionsPage"
              :class="{ active: $route.path === '/opinionsPage' }"
              @click="removeLayerLink"
              class="main-link"
              >{{ $t("nav.reviews") }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/branshesView"
              :class="{ active: $route.path === '/branshesView' }"
              @click="removeLayerLink"
              class="main-link"
              >{{ $t("nav.branches") }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/offersPage"
              :class="{ active: $route.path === '/offersPage' }"
              @click="removeLayerLink"
              class="main-link"
              >{{ $t("nav.offers") }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/contactPage"
              :class="{ active: $route.path === '/contactPage' }"
              @click="removeLayerLink"
              class="main-link"
              >{{ $t("nav.contact") }}</router-link
            >
          </li>
          <li>
            <div
              @click="removeLayerLink"
              data-bs-target="#emplyment"
              data-bs-toggle="modal"
              class="main-link employ-btn"
            >
              {{ $t("nav.employment") }}
            </div>
          </li>
          <li>
            <router-link
              to="/whousPage"
              :class="{ active: $route.path === '/whousPage' }"
              @click="removeLayerLink"
              class="main-link"
              >{{ $t("nav.who") }}</router-link
            >
          </li>
        </ul>
        <div class="flex-sm-gap-top">
          <div v-if="showArbic == 'en'" class="drop-doen-lang-btn">
            <div class="lang-text">English</div>
            <i class="fa-solid fa-chevron-down"></i>
            <div class="drop-down-content">
              <button @click="setEnglish()" class="web-lang active">
                English
              </button>
              <button @click="setArabic()" class="web-lang">Arabic</button>
            </div>
          </div>
          <div v-else>
            <div class="drop-doen-lang-btn">
              <div class="lang-text">العربية</div>
              <i class="fa-solid fa-chevron-down"></i>
              <div class="drop-down-content">
                <button @click="setArabic()" class="web-lang active">
                  العربية
                </button>
                <button @click="setEnglish()" class="web-lang">
                  الانجليزية
                </button>
              </div>
            </div>
          </div>
          <span class="coll-icon" @click="toggleSidebar">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
    </div>
    <div class="add-reverse">
      <div class="add-text">
        <div
          class="text-only w-sm-100"
          v-for="(newmain, i) in newsPaper"
          :key="newmain"
        >
          {{ newsPaper[i] }}
        </div>
      </div>
    </div>
  </section>

  <input
    type="text"
    hidden
    id="bla"
    data-bs-target="#doneContact"
    data-bs-toggle="modal"
  />
  <div
    class="modal fade small"
    id="doneContact"
    aria-hidden="true"
    aria-labelledby="doneContactLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="close-model-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa-solid fa-xmark"></i>
        </button>
        <div class="flex-group-me mb-1">
          <img class="done-img" :src="done" alt="" />
        </div>
        <div class="text-center mb-4 mod-text">
          تم الارسال بنجاح وسيتم التواصل معكم في اقرب وقت
        </div>
        <div class="flex-group-me mb-4">
          <button type="submit" class="main-btn lg" data-bs-dismiss="modal">
            المتابعة
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="emplyment"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <button
          type="button"
          class="close-model-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa-solid fa-xmark"></i>
        </button>
        <h5 class="head-modal mt-4 mb-4">{{ $t("nav.employment") }}</h5>
        <form action="" @submit.prevent="employ" ref="employForm">
          <div class="grid-main mb-4">
            <div class="main-input-cont">
              <h6 class="mb-2 font15">{{ $t("common.inputname") }}</h6>
              <input
                type="text"
                class="main-input def-in"
                :placeholder="$t('common.nameplace')"
                name="name"
                v-model="name"
              />
            </div>
            <div class="main-input-cont">
              <h6 class="mb-2 font15">{{ $t("common.inputphone") }}</h6>
              <input
                type="number"
                class="main-input def-in"
                :placeholder="$t('common.phoneplace')"
                name="phone"
                v-model="phone"
              />
            </div>
          </div>
          <div class="main-input-cont mb-4">
            <h6 class="mb-2 font15">{{ $t("common.inputemail") }}</h6>
            <input
              type="email"
              class="main-input def-in"
              :placeholder="$t('common.emailplace')"
              name="email"
              v-model="email"
            />
          </div>
          <div class="main-input-cont mb-4">
            <h6 class="mb-2 font15">{{ $t("common.Qualification") }}</h6>
            <input
              type="text"
              class="main-input def-in"
              :placeholder="$t('common.Qualification')"
              name="qualification"
              v-model="qualification"
            />
          </div>
          <div class="main-input-cont mb-4">
            <h6 class="mb-2 font15">{{ $t("common.yourCv") }}</h6>
            <label for="uploadCv" class="uploadCv"
              >{{ $t("common.uploadyourCv") }}
            </label>
            <input
              type="file"
              id="uploadCv"
              name="cv"
              ref="uploadVcMain"
              hidden
              accept="application/*"
              @change="uploadImage($event)"
            />
            <div class="main-float">
              <font-awesome-icon :icon="['fas', 'upload']" />
            </div>
            <div class="upload-area-cv mt-2">
              <div
                class="relative-parent"
                v-for="(image, key) in productImages"
                :key="key"
              >
                <div class="abs-mark-main" @click="removeImage(image, key)">
                  <font-awesome-icon :icon="['fas', 'xmark']" />
                </div>
                <div class="pdf-main">
                  <img class="pdf-im" :src="pdfImg" />
                  {{ image.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="main-input-cont mb-4">
            <h6 class="mb-2 font15">{{ $t("common.IDphoto") }}</h6>
            <input
              type="file"
              id="uploadimgreal"
              hidden
              multiple
              name="national_identity_images[]"
              accept="image/*"
              @change="uploadImageReal($event)"
            />
            <label for="uploadimgreal" class="uploadCv">
              {{ $t("common.IDphoto") }}</label
            >
            <div class="main-float">
              <font-awesome-icon :icon="['fas', 'camera']" />
            </div>
            <div class="upload-area-img mt-2">
              <div
                class="relative-parent"
                v-for="(image, key) in productImagesReal"
                :key="key"
              >
                <div class="abs-mark-main" @click="removeImageReal(image, key)">
                  <font-awesome-icon :icon="['fas', 'xmark']" />
                </div>
                <div>
                  <img class="preview" :ref="'imagereal'" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex-group-me mb-4">
            <button type="submit" :disabled="disabled" class="main-btn lg">
              <div v-if="!disabled">{{ $t("common.Sendmain") }}</div>

              <div class="loading" role="status" v-if="disabled">
                {{ $t("common.Sendpending") }}

                <div class="spinner-small-white" v-if="disabled"></div>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      newsPaper: [],
      showArbic: "ar",
      productImagesReal: [],
      productImages: [],
      disabled: false,
      rate: "",
      phone: "",
      email: "",
      name: "",
      message: "",
      done: require("../assets/imgs/Frame.png"),
      logo: require("../assets/imgs/Group 1 (1).png"),
      pdfImg: require("../assets/imgs/pdf.png"),
      loader: require("../assets/imgs/pdf.png"),
      loading: true,
      qualification: "",
      cv: [],
    };
  },

  methods: {
    removeLayerLink() {
      document.querySelector(".coll-icon").classList.remove("active");
      document.querySelector(".links").classList.remove("slideto");
      document.querySelector(".nav-layer").classList.remove("slideto");
    },
    toggleme() {
      document.querySelector(".coll-icon").classList.remove("active");
      document.querySelector(".links").classList.remove("slideto");
      document.querySelector(".nav-layer").classList.remove("slideto");
    },
    toggleSidebar() {
      document.querySelector(".coll-icon").classList.toggle("active");
      document.querySelector(".links").classList.toggle("slideto");
      document.querySelector(".nav-layer").classList.toggle("slideto");
    },

    // change language and local
    setArabic() {
      localStorage.setItem("locale", "ar");
      localStorage.setItem("lang", "ar");
      setTimeout(() => {
        this.$i18n.locale = "ar";
      }, 3000);

      // this.showArbic = this.$i18n.locale;
      location.reload();
    },
    setEnglish() {
      localStorage.setItem("locale", "en");
      localStorage.setItem("lang", "en");
      setTimeout(() => {
        this.$i18n.locale = "en";
      }, 3000);

      location.reload();
    },

    // upload multi imgs

    uploadImage(e) {
      let selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.productImages = [];
        this.productImages.push(selectedFiles[i]);
      }
    },

    removeImage(image, index) {
      this.productImages.splice(index, 1);
      this.$refs.uploadVcMain.value = "";
    },

    // for realimgs

    uploadImageReal(e) {
      let selectedFiles2 = e.target.files;
      for (let i = 0; i < selectedFiles2.length; i++) {
        this.productImagesReal.push(selectedFiles2[i]);
      }
      this.applyImageReal();
      console.log(this.productImagesReal);
    },

    removeImageReal(image, index) {
      this.productImagesReal.splice(index, 1);
      this.applyImageReal();
      //this.$refs.image[index].src = "" // You are hidding the 3rd one that is now in index 1.
    },
    applyImageReal() {
      for (let i = 0; i < this.productImagesReal.length; i++) {
        let reader2 = new FileReader();
        reader2.onload = (e) => {
          this.$refs.imagereal[i].src = reader2.result;
          console.log(e);
        };
        reader2.readAsDataURL(this.productImagesReal[i]);
      }
    },

    async getNews() {
      await axios.get("/home").then((response) => {
        this.newsPaper = response.data.data.news;
      });
    },

    async employ() {
      console.log("sgsgts");
      this.disabled = true;
      const fd = new FormData(this.$refs.employForm);
      fd.append("name", this.name);
      fd.append("cv", this.cv);
      fd.append("phone", this.phone);
      fd.append("email", this.email);
      fd.append("qualification", this.qualification);
      fd.append("national_identity_images[]", this.productImagesReal);

      await axios.post("/recruitment", fd).then((response) => {
        if (response.data.key == "success") {
          document.querySelector("#bla").click();
          console.log("done");

          this.name = "";
          this.email = "";
          this.phone = "";
          this.qualification = "";
          this.national_identity_images = "";
          this.productImagesReal = [];
          this.productImages = [];
        } else {
          this.$swal("", `${response.data.msg}`, "error");
        }
        this.disabled = false;
      });
    },
  },
  mounted() {
    this.showArbic = localStorage.getItem("locale");
    localStorage.setItem("lang", localStorage.getItem("locale"));
    if (localStorage.getItem("locale") === null) {
      localStorage.setItem("locale", "ar");
    }

    this.getNews();
  },
};
</script>

<style></style>
