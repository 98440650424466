<template>
  <div class="landing-page-us section-pad pad-bottom relative-parent">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-12 mb-3">
          <div class="top-land mb-4">
            <div class="icon-container">
              <font-awesome-icon :icon="['fas', 'thumbs-up']" />
            </div>
            <div class="land-text">
              {{ landings.title }}
            </div>
          </div>
          <h3 class="mb-4">
            <span class="color-main font-bold">{{ $t("nav.mainname") }}</span>
            {{ $t("nav.rental") }}
          </h3>
          <p class="land-p" v-html="landings.description"></p>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <div class="img-who px-4">
            <div class="img-who-box">
              <img :src="whoImg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <packagesView :packages="packages" :loader="loader" />
  <statsView :statistics="statistics" />
  <bookingView :booking="booking" />
</template>

<script>
import packagesView from "../components/packagesView.vue";
import statsView from "../components/statsView.vue";
import bookingView from "../components/bookingView.vue";
import axios from "axios";
export default {
  data() {
    return {
      whoImg: require("../assets/imgs/0e7f6d486f 1.png"),
      packages: [],
      booking: [],
      statistics: [],
      landings: [],
      loader: true,
    };
  },
  components: {
    packagesView,
    statsView,
    bookingView,
  },

  methods: {
    async getLanding() {
      await axios.get("/home").then((response) => {
        this.packages = response.data.data.packages;
        this.booking = response.data.data.booking;
        this.statistics = response.data.data.statistics;
        this.landings = response.data.data.slider;
        this.loader = false;

        // console.log(this.landings);
      });
    },
  },

  mounted() {
    this.getLanding();
  },
};
</script>

<style></style>
