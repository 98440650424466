<template>
  <div class="opinions-sec section-pad relative-parent pad-opinions">
    <div class="container">
      <div class="flex-group-me">
        <h4 class="centered-title font-bold relative-parent white">
          {{ $t("nav.reviews") }}
        </h4>
      </div>
      <div class="flex-group-me mb-3">
        <img :src="imgHead" alt="" />
      </div>

      <p class="land-p stats-p white mb-5" v-html="reviews.description"></p>

      <div class="not-yet" v-if="reviews.length == 0 && !loader">
        <div class="col-md-5 col-12 margin-auto">
          <div class="text-no">لا تتوفر تقييمات حالية</div>
        </div>
      </div>

      <div class="col-md-11 col-12 margin-auto">
        <div class="opinions-slider" v-if="!loader">
          <swiper
            :pagination="pagination"
            :modules="modules"
            slidesPerView="auto"
            :breakpoints="{
              '1000': {
                slidesPerView: 2,
                spaceBetween: 25,
              },

              '500': {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }"
            :slides-per-view="1"
            :space-between="10"
            :navigation="true"
            :freeMode="true"
            :parallax="true"
            class="mySwiper2"
            :autoplay="{
              delay: 33500,
              disableOnInteraction: false,
            }"
            :loop="false"
          >
            <swiper-slide
              class="home-item"
              v-for="slider in reviews.reviews"
              :key="slider"
            >
              <div class="opinion-card">
                <div class="flex-sm-gap">
                  <img class="opinion-img mb-3" :src="slider.image" alt="" />
                  <div class="opinion-info">
                    <div class="name-people mb-1">{{ slider.user_name }}</div>
                    <div class="rate-main">
                      <v-rating
                        half-increments
                        readonly
                        hover
                        color="#F2B823"
                        length="5"
                        size="28"
                        marginLeft="1"
                        v-model="slider.rate"
                        emptyIcon="mdi-star-outline"
                        fullIcon="mdi-star"
                        halfIcon="mdi-star-half"
                      ></v-rating>
                    </div>
                  </div>
                </div>
                <p class="land-p">{{ slider.complaint }}</p>
                <div class="abs-mark">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div v-else>
          <div class="main-loader-main" v-if="loader">
            <div class="spinner-square">
              <div class="square-1 square"></div>
              <div class="square-2 square"></div>
              <div class="square-3 square"></div>
            </div>
          </div>
        </div>
        <div class="flex-group-me mt-4">
          <button
            type="button"
            class="main-btn lg no-after"
            data-bs-target="#exampleModalToggle"
            data-bs-toggle="modal"
          >
            {{ $t("common.sendreview") }}
          </button>
        </div>
      </div>
    </div>
    <img class="top-img" :src="topimg" alt="" />
    <img class="top-img-flip" :src="topimg2" alt="" />
    <div class="back-div-line"></div>
    <div class="back-div-line2"></div>
  </div>
  <div
    class="modal fade"
    id="exampleModalToggle"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <button
          type="button"
          class="close-model-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa-solid fa-xmark"></i>
        </button>
        <h5 class="head-modal mt-4 mb-4">تقييم</h5>
        <form action="" @submit.prevent="sendRate" ref="rateForm">
          <div class="img-regester-cont mb-3 mt-3">
            <label for="img-up" class="img-edit">
              <input
                name="image"
                type="file"
                id="img-up"
                hidden
                accept="image/*"
                @change="uploadImage"
              />
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'pen-to-square']" />
              </span>
            </label>
            <img id="change-profile" :src="edit_avatar" alt="" />
          </div>
          <div class="main-input-cont mb-3">
            <h6 class="mb-2 font15">{{ $t("common.inputname") }}</h6>
            <input
              type="text"
              class="main-input"
              :placeholder="$t('common.nameplace')"
              name="user_name"
              v-model="user_name"
            />

          </div>
          <div class="grid-main mb-3">
            <div class="main-input-cont">
              <h6 class="mb-2 font15">{{ $t("common.inputemail") }}</h6>
              <input
                type="text"
                class="main-input"
                :placeholder="$t('common.emailplace')"
                name="email"
                v-model="email"
              />
 
            </div>
            <div class="main-input-cont">
              <h6 class="mb-2 font15">{{ $t("common.inputphone") }}</h6>
              <input
                type="number"
                class="main-input"
                :placeholder="$t('common.phoneplace')"
                name="phone"
                v-model="phone"
              />

            </div>
          </div>
          <div class="flex-rate-modal mb-3">
            <h6 class="dot-after">{{ $t("common.Starrating") }}</h6>
            <div class="rate-us">
              <v-rating
                half-increments
                hover
                color="#F2B823"
                length="5"
                size="48"
                marginLeft="1"
                v-model="rate"
                emptyIcon="mdi-star-outline"
                fullIcon="mdi-star"
                halfIcon="mdi-star-half"
              ></v-rating>
            </div>
          </div>
          <div class="main-input-cont mb-3">
            <h6 class="mb-2 font15">{{ $t("common.comment") }}</h6>
            <textarea
              class="main-textarea"
              id=""
              rows="6"
              :placeholder="$t('common.comment')"
              name="complaint"
              v-model="complaint"
            ></textarea>

          </div>
          <div class="flex-group-me mb-4">
            <button type="submit" :disabled="disabled" class="main-btn lg">
              <div v-if="!disabled">{{ $t("common.Sendmain") }}</div>

              <div class="loading" role="status" v-if="disabled">
                {{ $t("common.Sendpending") }}

                <div class="spinner-small-white" v-if="disabled"></div>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import axios from "axios";

import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
export default {
  props: ["reviews", "loader"],
  data() {
    return {
      edit_avatar: require("../assets/imgs/solia.jpg"),
      disabled: false,
      rate: "",
      phone: "",
      email: "",
      user_name: "",
      complaint: "",
      image: "",
      imgHead: require("../assets/imgs/title-head 1.png"),
      topimg: require("../assets/imgs/app-car 1.png"),
      topimg2: require("../assets/imgs/flipcar 1.png"),
      done: require("../assets/imgs/Frame.png"),
      desc: "",
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
      },

      modules: [Pagination, Autoplay, Navigation],
    };
  },

  methods: {
    // uploadImage
    uploadImage(e) {
      const file = e.target.files[0];
      if (e.target.files[0]) {
        this.edit_avatar = URL.createObjectURL(file);
      } else {
        this.edit_avatar = require("../assets/imgs/solia.jpg");
      }
    },
    async sendRate() {
      // console.log(document.querySelector("#doneContact"));
      this.disabled = true;
      const fd = new FormData(this.$refs.rateForm);
      fd.append("user_name", this.user_name);
      fd.append("phone", this.phone);
      fd.append("email", this.email);
      fd.append("rate", this.rate);
      fd.append("complaint", this.complaint);
      fd.append("image", this.edit_avatar);

      await axios.post("/complaint", fd).then((response) => {
        if (response.data.key == "success") {
          document.querySelector("#bla").click();

          this.user_name = "";
          this.email = "";
          this.phone = "";
          this.rate = "";
          this.complaint = "";
          this.edit_avatar = require("../assets/imgs/solia.jpg");
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          if(response.data.msg == "يجب أن يكون ملفًا من نوع : jpg, bmp, png."){
            this.$swal("", `الصورة مطلوبة`, "error");
          }else{
            this.$swal("", `${response.data.msg}`, "error");
          }
         
        }
        this.disabled = false;
      });
    },
  },
};
</script>

<style>
.opinions-slider .swiper-pagination {
  display: none;
}
.opinions-slider .swiper-button-prev {
  right: -60px;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
}
.opinions-slider .swiper-button-next {
  left: -60px;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #000;
}
.opinions-slider .swiper {
  overflow: visible;
}
.opinions-slider .swiper-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
}
.opinions-slider .swiper-slide-active {
  opacity: 1;
  visibility: visible;
}
.opinions-slider .swiper-slide-active + .swiper-slide {
  opacity: 1;
  visibility: visible;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
</style>
