<template>
  <div class="packages section-pad">
    <div class="container">
      <div class="col-md-10 margin-auto">
        <div class="packages-main">
          <h5 class="mb-4 text-center color-main">
            {{ $t("common.Special") }}
          </h5>
          <div class="row" v-if="packages.length">
            <div
              class="col-lg-4 col-md-6 col-12 mb-3"
              v-for="(packagemain, i) in packages"
              :key="packagemain"
            >
              <div class="my-pack">
                <div
                  class="packages-head"
                  :style="{ 'background-color': packagemain.color }"
                >
                  {{ packagemain.title }}
                </div>
                <div class="pack-body" ref="bodydesc" v-if="!readMore[i]">
                  {{ packagemain.description.substring(0, 200) }}
                  <span v-if="packagemain.description.length > 200">
                    <button
                      class="show-default"
                      @click="showMore(i)"
                      v-if="!readMore[i]"
                     
                    >
                      المذيد
                    </button>
                  </span>
                </div>
                <div class="pack-body" ref="bodydesc" v-if="readMore[i]">
                  {{ packagemain.description }}

                  <button
                    class="show-default"
                    @click="showLess(i)"
                    v-if="readMore[i]"
                  >
                    عرض اقل
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="main-loader-main" v-if="loader">
              <div class="spinner-square">
                <div class="square-1 square"></div>
                <div class="square-2 square"></div>
                <div class="square-3 square"></div>
              </div>
            </div>
            <div class="not-yet" v-if="!loader">
              <div class="col-md-5 col-12 margin-auto">
                <div class="text-no">{{ $t("common.nopackages") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["packages", "loader"],
  data() {
    return {
      // showMore : false,
      // showLess : false,
      readMore: [false],
    };
  },
  components: {},
  methods: {
    showMore(i) {
      this.readMore[i] = true;
    },
    showLess(i) {
      this.readMore[i] = false;
    },
  },
};
</script>

<style></style>
