<template>
  <div class="footer section-pad footer-pad relative-parent">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-12 mb-4">
          <div class="footer-colomn px-3">
            <h5 class="mb-5 footer-head">{{ $t("common.Subscribe") }}</h5>
            <p class="land-p mb-4" v-html="desc"></p>
            <form action="" @submit.prevent="subscribe" ref="subscribe">
              <div class="form-back">
                <input
                  type="email"
                  class="input-email"
                  name="email"
                  v-model="email"
                  :placeholder="$t('common.inputPlace')"
                />
                <button class="main-btn">
                  <div v-if="!disabled">{{ $t("common.inputbtn") }}</div>

                  <div class="loading" role="status" v-if="disabled">
                    {{ $t("common.inputbtnpend") }} ...
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-4">
          <div class="footer-colomn px-3">
            <h5 class="mb-5 footer-head">{{ $t("common.links") }}</h5>
            <div class="footer-links">
              <router-link to="/whousPage" class="footer-link mb-4">
                <font-awesome-icon
                  class="color-main"
                  :icon="['fas', 'chevron-left']"
                />
                <div class="link-text-m">{{ $t("nav.who") }}</div>
              </router-link>
              <router-link to="/carsPage" class="footer-link mb-4">
                <font-awesome-icon
                  class="color-main"
                  :icon="['fas', 'chevron-left']"
                />
                <div class="link-text-m">{{ $t("nav.cars") }}</div>
              </router-link>
              <router-link to="/opinionsPage" class="footer-link mb-4">
                <font-awesome-icon
                  class="color-main"
                  :icon="['fas', 'chevron-left']"
                />
                <div class="link-text-m">{{ $t("nav.reviews") }}</div>
              </router-link>
              <router-link to="/branshesView" class="footer-link mb-3">
                <font-awesome-icon
                  class="color-main"
                  :icon="['fas', 'chevron-left']"
                />
                <div class="link-text-m">{{ $t("nav.branches") }}</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="footer-colomn">
            <h5 class="mb-5 footer-head">{{ $t("nav.contact") }}</h5>
            <div class="footer-links">
              <router-link to="/contactPage" class="footer-link mb-4">
                <div class="foot-icon">
                  <font-awesome-icon :icon="['fas', 'location-dot']" />
                </div>
                <div class="link-text-m">{{ location }}</div>
              </router-link>
              <a v-bind:href="'mailto:' + emailGet" class="footer-link mb-4">
                <div class="foot-icon">
                  <font-awesome-icon :icon="['far', 'envelope']" />
                </div>
                <div class="link-text-m">{{ emailGet }}</div>
              </a>
              <a
                v-bind:href="'https://api.whatsapp.com/send?phone=' + phone"
                class="footer-link mb-4"
              >
                <div class="foot-icon">
                  <font-awesome-icon :icon="['fas', 'phone']" />
                </div>
                <div class="link-text-m">{{ phone }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="foot-top" :src="imgTop" alt="" />
    <img class="foot-left" :src="imgLeft" alt="" />
    <img class="foot-left-flip" :src="imgFlipLeft" alt="" />
  </div>
  <div class="copy-right">
    <div class="container">
      <div class="copy-right-main">
        <div class="right-foot">{{ $t("common.rightFooter") }}</div>
        <div class="right-foot">{{ $t("common.leftFooter") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      disabled: false,
      imgTop: require("../assets/imgs/facts-right 1.png"),
      imgLeft: require("../assets/imgs/footer-right 4.png"),
      imgFlipLeft: require("../assets/imgs/footer-flip4.png"),
      email: "",
      emailGet: "",
      location: "",
      phone: "",
      desc: "",
    };
  },
  components: {},

  methods: {
    async getLanding() {
      await axios.get("/home").then((response) => {
        this.location = response.data.data.contact_us.address;
        this.phone = response.data.data.contact_us.phone;
        this.emailGet = response.data.data.contact_us.email;
        this.desc = response.data.data.contact_us.paragraphNewsletter;
      });
    },
    async subscribe() {
      // console.log(document.querySelector("#doneContact"));
      this.disabled = true;
      const fd = new FormData(this.$refs.subscribe);

      fd.append("email", this.email);

      await axios.post("/sign-up", fd).then((response) => {
        // console.log(response.data);
        if (response.data.key == "success") {
          this.$swal("", `تم الاشتراك بنجاح`, "success");
          this.email = "";
        } else {
          this.$swal("", `${response.data.msg}`, "error");
        }
        this.disabled = false;
      });
    },
  },

  mounted() {
    this.getLanding();
  },
};
</script>

<style></style>
