<template>
  <div class="home">
    <!-- langing  -->
    <landingView :landings="landings" :loader="loader"/>
    <!-- packages -->
    <packagesView :packages="packages" :loader="loader"/>
    <!-- booking -->
    <bookingView :booking="booking" />
    <!-- stats -->
    <statsView :statistics="statistics" />
    <!-- cars -->
    <carsView :fleet="fleet" :loader="loader" :contact_us = "contact_us" />
    <!-- opinions -->
    <opinionsView :reviews="reviews" :loader="loader"/>
    <!-- cobtact-boxes -->
    <contactBoxes :contact_us="contact_us" :loader="loader" />
    <!-- cobtact-form -->
    <contactForm />
    <!-- map -->
    <mapView />
    <!-- footer -->
  </div>
</template>

<script>
import landingView from "../components/landingView.vue";
import packagesView from "../components/packagesView.vue";
import bookingView from "../components/bookingView.vue";
import statsView from "../components/statsView.vue";
import carsView from "../components/carsView.vue";
import opinionsView from "../components/opinionsView.vue";
import contactBoxes from "../components/contactBoxes.vue";
import contactForm from "../components/contactForm.vue";
import mapView from "../components/mapView.vue";
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      landings: [],
      packages: [],
      booking: [],
      statistics: [],
      fleet: [],
      reviews: [],
      contact_us: [],
      loader: true,
    };
  },
  components: {
    landingView,
    packagesView,
    bookingView,
    statsView,
    carsView,
    opinionsView,
    contactBoxes,
    contactForm,
    mapView,
  },
  methods: {
    async getLanding() {
      await axios.get("/home").then((response) => {
        this.landings = response.data.data.slider;
        this.packages = response.data.data.packages;
        this.booking = response.data.data.booking;
        this.statistics = response.data.data.statistics;
        this.fleet = response.data.data.fleet;
        this.reviews = response.data.data.reviews;
        this.contact_us = response.data.data.contact_us;
        this.loader = false;
        // console.log(this.landings);
      });
    },
  },

  mounted() {
    this.getLanding();
  },
};
</script>
