import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import carsPage from '../views/carsPage.vue'
import opinionsPage from '../views/opinionsPage.vue'
import branshesView from '../views/branshesView.vue'
import offersPage from '../views/offersPage.vue'
import contactPage from '../views/contactPage.vue'
import whousPage from '../views/whousPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/carsPage',
    name: 'carsPage',
    component: carsPage
  },
  {
    path: '/opinionsPage',
    name: 'opinionsPage',
    component: opinionsPage
  },
  {
    path: '/branshesView',
    name: 'branshesView',
    component: branshesView
  },
  {
    path: '/offersPage',
    name: 'offersPage',
    component: offersPage
  },
  {
    path: '/contactPage',
    name: 'contactPage',
    component: contactPage
  },
  {
    path: '/whousPage',
    name: 'whousPage',
    component: whousPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router
